import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			mapSelectedUnit: {},
			mapUnits: [],
			highlightUnit: undefined,
			forceMap: false,
			mapItems: undefined
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {

		var req = {
			"fields": {
				"u.path": {
					"field": "path",
					"filter": "vlang"
				},
				"u.in units": {
					"field": "in units"
				},

				"u.facilityName": {
					"field": "facilityName",
					"filter": "vautolang"
				},
				"u.name": {
					"field": "name",
					"filter": "vautolang"
				},
				"u.type": {
					"field": "type",
					"filter": "vsoption",
					"filterparams": [
						"hpunit.type"
					]
				},
				"u.address.street": {
					"field": "address.street"
				},
				"u.address.city": {
					"field": "address.city"
				},
				"u.petsRestrictions": {
					"field": "petsRestrictions"
				},
				"u.loc.coordinates": {
					"field": "loc.coordinates"
				}
			},
			"filter": {
				"adults": 2,
				"children": 0,
				"babys": 0
			},
			"sorting": "random",
			"view": "map",
			"alternatives": true,
			"max": 250,
			"fuzzy": true,
			"splitAlternatives": false,
			"mapsearch": true,
			"abtest": 2,

		};

		doReq('searchUnits', req)
			.then((res) => {
				//	console.log('Units::', res.units);
				this.mapUnits = res.units;
				var i = 0;
				var mapItems = [];
				this.mapUnits.forEach(function (item) {
					if (item.length === 3) {
						i++;
						mapItems[i] = new Array(item[0], item[1], item[2]);
					} else if (item.length > 3) {
						for (let a = 2; a < item.length; a++) {
							i++;
							mapItems[i] = new Array(item[0], item[1], item[a]);
						}
					}

				});

				this.mapItems = mapItems;
				//	this.mapSelectedUnit = u;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	methods: {

		getUnitSearchPath: function (path, o) {
			return path;
		},

		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},

		myEventHandler(e) {
			console.log(window.innerWidth);
		}
	},
	watch: {
		mapSelectedUnit: function (nv) {
			//	V.log("mapSelectedUnit", nv);
			if (nv && nv.lazy) {

				var req = {
					"fields": {
						"u.path": {
							"field": "path",
							"filter": "vlang"
						},
						"u.in units": {
							"field": "in units"
						},

						"u.facilityName": {
							"field": "facilityName",
							"filter": "vautolang"
						},
						"u.name": {
							"field": "name",
							"filter": "vautolang"
						},
						"u.type": {
							"field": "type",
							"filter": "vsoption",
							"filterparams": [
								"hpunit.type"
							]
						},
						"u.beds": {
							"field": "beds",
						},
						"u.rooms": {
							"field": "rooms",
						},
						"u.bedrooms": {
							"field": "bedrooms",
						},
						"u.bathrooms": {
							"field": "bathrooms",
						},
						"u.living_room": {
							"field": "living_room",
						},
						"u.pets": {
							"field": "pets",
						},
						"u.idxImage": {
							"field": "idxImage",
						},
						"u.squareMeter": {
							"field": "squareMeter",
						},
						"u.address.street": {
							"field": "address.street"
						},
						"u.address.city": {
							"field": "address.city"
						},
						"u.petsRestrictions": {
							"field": "petsRestrictions"
						},
						"u.loc.coordinates": {
							"field": "loc.coordinates"
						}
					},
					"filter": {
						"adults": 0,
						"children": 0,
						"babys": 0
					},
					ids: [nv.info[2]]
				};

				V.log("mapSelectedUnitRequest::", req);


				doReq('searchUnits', req)
					.then((res) => {
						V.log("mapSelectedUnit:::", res.units);
						if (res.units && res.units.length) {
							Object.assign(nv, res.units[0]);
							nv.lazy = false;
						}

					});

			}
		}
	}

};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: { "trigger-id": _vm.triggerId, "apply-label": _vm.applyLabel },
      on: { closed: _vm.onClosed }
    },
    [
      _c(
        "table",
        { staticClass: "guestbox" },
        [
          _vm._l(_vm.sections, function(l, what) {
            return _c("tr", { staticClass: "gb-row" }, [
              _c("td", { staticClass: "gb-td label" }, [
                _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canDecr(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, -1)
                      }
                    }
                  },
                  [_vm._v("-")]
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canInc(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, 1)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          }),
          _vm._v(" "),
          _c("tr", { staticClass: "gb-row select-box" }, [
            _c("td", { staticClass: "gb-td label" }, [
              _c("label", [_vm._v("Kinder über 2 Jahre")])
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.children,
                      expression: "searchData.children"
                    }
                  ],
                  staticClass: "form-control form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "children",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("0")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "gb-row select-box" }, [
            _c("td", { staticClass: "gb-td label" }, [
              _c("label", [_vm._v("Kinder bis 2 Jahre")])
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.babys,
                      expression: "searchData.babys"
                    }
                  ],
                  staticClass: "form-control form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.searchData,
                        "babys",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("0")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }